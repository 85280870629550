import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import axios from 'axios';
import type { Dispatch } from '@reduxjs/toolkit';
import latinize from 'latinize';

import scrollToTop from 'adapters/scrollToTop';
import apiHttp from 'services/http/apiHttp';
import getLocal from 'services/getLocal';
import promiseWrapper from 'functions/general/promiseWrapper';
import useLoading from 'hooks/useLoading';
import MainContextProvider, { MainContextIntBase } from './MainContextProvider';
import { AnalysisResponse } from  'pages/NewDocument/types';
import { IntCompleteReport } from  'pages/Analysis/types';
import type { IntUserResponse } from 'services/local/types';

export type MainContextProviderProductionProps = {
  dispatch: Dispatch;
  componentName: string;
  children: string | React.ReactNode;
  user?: IntUserResponse;
  editMode: boolean;
  isLoggedIn: boolean;
  pendingCredits: boolean;
  signIn: boolean;
  signUp: boolean;
  used: number;
  total: number;
  showMenu: boolean;
  isLoading: boolean;
  message: string;
  isOpen: boolean;
  subject: string;
  isOpenContactLogin: boolean;
  subjectContactLogin: string;
  name: string;
  analysisReport?: AnalysisResponse;
  historyReport?: IntCompleteReport;
  sendEmail: boolean;
}

const MainContextProviderProduction = ({
  dispatch,
  children,
  user,
  isLoggedIn,
  pendingCredits,
  signIn,
  signUp,
  used,
  total,
  isLoading,
  message,
  isOpen,
  subject,
  componentName,
  isOpenContactLogin,
  subjectContactLogin,
  showMenu,
  name,
  analysisReport,
  historyReport,
  sendEmail,
  editMode
}: MainContextProviderProductionProps) => {
  const {openLoading, closeLoading} = useLoading(toastr);
  const local = getLocal(localStorage);

  const history = useNavigate();
  const value: MainContextIntBase = {
    closeLoading,
    openLoading,
    scrollToTop,
    dispatch,
    history,
    toastr,
    local,
    api: apiHttp(axios, promiseWrapper(toastr)),
    user,
    isLoggedIn,
    pendingCredits,
    used,
    total,
    showMenu,
    signIn,
    signUp,
    isLoading,
    message,
    isOpen,
    subject,
    componentName,
    isOpenContactLogin,
    subjectContactLogin,
    name,
    editMode,
    analysisReport,
    historyReport,
    sendEmail,
    latinize
  };
  return (
    <MainContextProvider value={value}>
      {children}
    </MainContextProvider>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.auth.user,
  editMode: state.menu.editMode,
  isLoggedIn: state.auth.isLoggedIn,
  pendingCredits: state.auth.pendingCredits,
  signIn: state.signIn.signIn,
  signUp: state.signUp.signUp,
  used: state.usage.used,
  total: state.usage.total,
  showMenu: state.menu.showMenu,
  isLoading: state.loading.isLoading,
  message: state.loading.message,
  isOpen: state.contactUs.isOpen,
  subject: state.contactUs.subject,
  isOpenContactLogin: state.contactLogin.isOpen,
  subjectContactLogin: state.contactLogin.subject,
  name: state.name.name,
  analysisReport: state.analysisReport.analysisReport,
  historyReport: state.analysisReport.fullReport,
  sendEmail: state.analysisReport.sendEmail
});

export default connect(mapStateToProps)(MainContextProviderProduction) as any;
