import React, { useCallback, useEffect, useState } from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import { Aside } from './elements';
import TutorialCoverAddingText from 'components/molecules/TutorialCovers/TutorialCoverAddingText';
import TutorialCoverBulkActions from 'components/molecules/TutorialCovers/TutorialCoverBulkActions';
import TutorialCoverExploreWords from 'components/molecules/TutorialCovers/TutorialCoverExploreWords';
import TutorialCoverExporting from 'components/molecules/TutorialCovers/TutorialCoverExporting';
import TutorialCoverOrganising from 'components/molecules/TutorialCovers/TutorialCoverOrganising';
import TutorialCoverSharing from 'components/molecules/TutorialCovers/TutorialCoverSharing';
import TutorialMessageAddingText from 'components/molecules/TutorialMessages/TutorialMessageAddingText';
import TutorialMessageBulkActions from 'components/molecules/TutorialMessages/TutorialMessageBulkActions';
import TutorialMessageExploreWords from 'components/molecules/TutorialMessages/TutorialMessageExploreWords';
import TutorialMessageExporting from 'components/molecules/TutorialMessages/TutorialMessageExporting';
import TutorialMessageOrganising from 'components/molecules/TutorialMessages/TutorialMessageOrganising';
import TutorialMessageSharing from 'components/molecules/TutorialMessages/TutorialMessageSharing';
import { 
  TUTORIAL_ADDING_TEXTS,
  TUTORIAL_EXPLORE_WORDS,
  TUTORIAL_EXPORTING,
  TUTORIAL_ORGANISING,
  TUTORIAL_SHARING,
  TUTORIAL_BULK_ACTIONS
} from 'constants/tutorials';
import homeIcon from 'images/pages/Home/home.svg';
import homeWhiteIcon from 'images/pages/Home/homeWhite.svg';
import expandIcon from 'images/leftMenu/expand.svg';
import collapseIcon from 'images/leftMenu/collapse.svg';
import analysisIcon from 'images/pages/Home/newDocument.svg';
import analysisWhiteIcon from 'images/pages/Home/newDocumentWhite.svg';
import myDocumentsIcon from 'images/leftMenu/myDocuments.svg';
import myDocumentsWhiteIcon from 'images/leftMenu/myDocumentsWhite.svg';
import wordExplorerIcon from 'images/leftMenu/wordExplorer.svg';
import wordExplorerWhiteIcon from 'images/leftMenu/wordExplorerWhite.svg';
import customizeTargetIcon from 'images/leftMenu/customizeTarget.svg';
import customizeTargetWhiteIcon from 'images/leftMenu/customizeTargetWhite.svg';
import webCheckerIcon from 'images/leftMenu/webChecker.svg';
import webCheckerWhiteIcon from 'images/leftMenu/webCheckerWhite.svg';
import LeftMenuItem from './LeftMenuItem';
import LeftMenuLogo from './LeftMenuLogo';
import ContactUs from './ContactUs';
import Storage from './Storage';
import SubMenu from './SubMenu';
import type { MenuItem, LeftMenuProps } from './types';

const MENU_ITEMS: MenuItem[] = [
  { id: 'Home', name: 'Home', url: '/', active: false, iconWhite: homeWhiteIcon, icon: homeIcon, highlight: false, auth: true },
  { id: 'Analysis', name: 'Analysis', url: '/analysis', active: false, iconWhite: analysisWhiteIcon, icon: analysisIcon, highlight: false, auth: false },
  { id: 'MyDocuments', name: 'Documents', url: '/documents/my-documents/1', active: false, iconWhite: myDocumentsWhiteIcon, icon: myDocumentsIcon, highlight: false, auth: true },
  { id: 'WordExplorer', name: 'Explorer', url: '/word-explorer', active: false, iconWhite: wordExplorerWhiteIcon, icon: wordExplorerIcon, highlight: false, auth: false },
  { id: 'Customize', name: 'Customize', url: '/customize/audience-profile', active: false, iconWhite: customizeTargetWhiteIcon, icon: customizeTargetIcon, highlight: false, auth: true }
];

const MENU_ITEMS_AUTH: MenuItem[] = MENU_ITEMS.map((item: MenuItem) => {
  item.auth = true;
  return item;
});

const IGNORE: string[] = ['WebCheckerMenu', 'Error', 'Faq', 'Page Not Found', 'Privacy Policy', 'Terms', 'How To', 'Glossary', 'Cookie Policy', 'ReadabilityPublic', 'PublicBrainPain', 'Comparison', 'PublicSentimentAnalysisDispatcher', 'Touchpoints', 'MyECommerceDocuments', 'NewECommerce', 'ECommerce', 'ECommerceProduct', 'ECommerceCreate'];

const activateItem = (id: string, items: MenuItem[]) =>
  items.map((item: MenuItem) => {
    if (item.id === id) return {...item, active: true};
    return {...item, active: false};
  });

const highlightMenuItem = (tutorial: string): boolean =>
  [TUTORIAL_ADDING_TEXTS, TUTORIAL_EXPORTING, TUTORIAL_ORGANISING, TUTORIAL_SHARING, TUTORIAL_BULK_ACTIONS].includes(tutorial);

const getMenuItems = (items: MenuItem[], tutorial: string, tutorialStep: number): MenuItem[] => {
  const copyMenuItems = [...items];
  copyMenuItems[1].highlight = false;
  copyMenuItems[2].highlight = false;
  copyMenuItems[3].highlight = false;
  if (tutorialStep !== 0) return copyMenuItems;
  if (tutorial === TUTORIAL_ADDING_TEXTS) {
    copyMenuItems[1].highlight = true;
  } else if (highlightMenuItem(tutorial)) {
    copyMenuItems[2].highlight = true;
  } else if (tutorial === TUTORIAL_EXPLORE_WORDS) {
    copyMenuItems[3].highlight = true;
  }
  return copyMenuItems;
};

const LeftMenu = ({active, leftMenuClick, canOpen, isOpen, setIsOpen, auth = false}: LeftMenuProps) => {
  const { used, total, tutorial, tutorialStep, local, user } = useMainContext();
  const [items, setItems] = useState<MenuItem[]>(auth? MENU_ITEMS_AUTH: MENU_ITEMS);

  const updateItems = useCallback(() => {
    const filtered = items.filter((item: MenuItem) => item.id === active && item.active);
    if (filtered.length === 1) return;
    if (IGNORE.includes(active)) return;
    const find = items.find((item: MenuItem) => item.id === 'NewECommerce');
    if (find?.active) {
      if (['MyECommerceDocuments', 'NewECommerce', 'ECommerce', 'ECommerceProduct', 'ECommerceCreate'].includes(active)) return;
    }
    setItems(activateItem(active, items));
  }, [active, items]);

  useEffect(() => {
    updateItems();
  }, [updateItems]);


  const toggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (canOpen) {
      local.leftMenuState.setLocalStorageLeftMenuState(isOpen);
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (!canOpen && isOpen) {
      setIsOpen(false);
    }
  }, [canOpen, isOpen, setIsOpen]);

  const menuItems: MenuItem[] = getMenuItems(items, tutorial, tutorialStep);

  return (
    <Aside $active={isOpen} $canOpen={canOpen}>
      <div className='menu'>
        <LeftMenuLogo open={isOpen} />
        <button onClick={toggle} className={getClassName(canOpen)}>
          <img src={isOpen? collapseIcon: expandIcon} alt="icon" />
        </button>
        <div className='menu'>
        <TutorialCoverAddingText steps={[0]} doNext={false} />
        <TutorialCoverBulkActions steps={[0]} doNext={false} />
        <TutorialCoverExploreWords steps={[0]} doNext={false} />
        <TutorialCoverExporting steps={[0]} doNext={false} />
        <TutorialCoverOrganising steps={[0]} doNext={false} />
        <TutorialCoverSharing steps={[0]} doNext={false} />
        {menuItems.map((item) => (
          <LeftMenuItem key={item.id} {...item} open={isOpen} leftMenuClick={leftMenuClick} />
        ))}
        {user?.user.email.endsWith('@tigim.co') && (
          <LeftMenuItem
            id='WebChecker'
            name='WebChecker'
            url='/web-checker/simple'
            active={active === 'WebCheckerMenu'}
            iconWhite={webCheckerWhiteIcon}
            icon={webCheckerIcon}
            highlight={false}
            auth={true}
            open={isOpen}
            leftMenuClick={leftMenuClick} />
        )}
        <ContactUs active={isOpen} />
        {user?.user.email.endsWith('@tigim.co') && (
          <SubMenu active={active} isOpen={isOpen} />
        )}
        </div>
        {active === 'MyDocuments' && <Storage used={used} total={total} isOpen={isOpen} />}
        <TutorialMessageAddingText step={0} />
        <TutorialMessageBulkActions step={0}/>
        <TutorialMessageExploreWords step={0} />
        <TutorialMessageExporting step={0} />
        <TutorialMessageOrganising step={0} />
        <TutorialMessageSharing step={0}/>
      </div>
    </Aside>
  );
};

export default LeftMenu;

const getClassName = (canOpen: boolean) => canOpen? 'activator': 'activator disabled';
